ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.Element {
  margin: 10px;
  flex-basis: 100%;
}

.video-thumbnail {
  width: 100%;
  height: auto;
}

h3 {
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  color: #000;
  display: inline;
  position: initial;
  top: 0;
}

a {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .Element {
    flex-basis: calc(25% - 20px);
  }
  h3 {
    font-size: 1.2rem;
    text-align: left;
  }
}

@media screen and (max-width: 426px) {
  ul {
    flex-direction: column;
  }
  .Element {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .video-thumbnail {
    width: 150px;
    margin-right: 10px;
  }
  h3 {
    font-size: 1rem;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #c01111;
}

.pagination button:disabled {
  background-color: #c66c6c;
  cursor: not-allowed;
}

.pagination p {
  margin: 0 10px;
  font-size: 16px;
  color: #333;
}

@media screen and (max-width: 768px) {
  .pagination button {
    padding: 8px 16px;
    font-size: 14px;
    margin: 0 5px;
  }
  .pagination p {
    font-size: 14px;
  }
  .Element {
    width: 30%;
  }
}
