.playlist-list {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
}

.playlist-list h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em;
  color: #333; 
}

.playlist-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  gap: 20px;
}

.playlist-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.playlist-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.playlist-thumbnail {
  width: 100%;
  height: auto;
}

.playlist-details {
  padding: 15px;
}

.playlist-title {
  font-size: 1.2em;
  margin: 5px 0;
  color: #000;
  font-weight: bold;
}

.playlist-description {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

.playlist-link {
  text-decoration: none;
  color: inherit;
}

.playlist-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .playlist-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust columns for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .playlist-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Smaller columns for mobile */
  }

  .playlist-title {
    font-size: 1em;
  }

  .playlist-description {
    font-size: 0.8em;
  }
}
