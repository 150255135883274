/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

/* Header and Navigation */
header {
  background-color: #ff0000;
  padding: 20px;
  color: white;
  text-align: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.channel-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

#heading {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  color: white;
}

nav {
  background-color: #ff0000;
  padding: 1px 0px;
  display: block;
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav ul {
  list-style: none;
  padding: 0;
  display: block;
  justify-content: left;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  border-bottom: 3px solid transparent;
  transition: background-color 0.3s, color 0.3s, border-bottom-color 0.3s;
}

nav ul li a:hover {
  color: #ffffff;
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

nav ul li a .active {
  color: #ffffff;
  font-weight: bold;
  border-bottom-color: #ffffff;
}

/* Video and Playlist Styles */
.video-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.video-item, .playlist-item {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.video-item:hover, .playlist-item:hover {
  transform: translateY(-5px);
}

.thumbnail-container {
  position: relative;
}

.video-thumbnail, .playlist-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.video-info {
  margin-top: 10px;
}

.video-info h4 {
  font-size: 1rem;
  margin: 0 0 5px;
  color: #333;
}

.video-info p {
  font-size: 0.85rem;
  color: #777;
}

/* Page Layout */
.home-page, .video-page {
  padding: 20px;
}

.related-videos {
  margin-top: 20px;
}

.related-videos h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.video-player {
  margin-bottom: 20px;
}

.playlist-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.spinner {
  text-align: center;
  font-size: 1.2rem;
  color: #333;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .video-list {
    flex-direction: column;
    align-items: center;
  }

  .video-item, .playlist-item {
    width: 100%;
    max-width: 350px;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the footer sticks at the bottom */
}

footer {
  background-color: #ff0000; /* Background color for the footer */
  color: white; /* Text color for the footer */
  text-align: center;
  padding: 10px 0;
  position: sticky; /* Stick the footer at the bottom */
  bottom: 0;
  width: 100%; /* Make the footer take full width */
}
