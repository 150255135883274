.playlist-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.playlist-item:hover {
  transform: scale(1.05);
}

.playlist-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.playlist-title {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}
