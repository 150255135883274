.video-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;
}

.video-section {
  flex: 3; 
}

.video-player {
  position: relative;
  width: 90%; 
  padding-bottom: 56.25%; 
  height: 0;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
}

.video-details {
  margin-top: 15px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: normal; 
  overflow-wrap: break-word; 
  word-break: break-word; 
}

.video-details p {
  line-height: 1.5; 
  margin-bottom: 10px; 
}

.video-details p::after {
  content: ''; 
}

.related-videos {
  flex: 1;
  margin-left: 20px;
}

.related-videos ul {
  list-style-type: none;
  padding: 0;
}

.related-videos li {
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
}

.related-videos li:hover {
  cursor: pointer;
}

.related-videos .thumbnail-wrapper {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.related-videos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.related-videos p {
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
}
