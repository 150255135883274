.shorts-page {
  padding: 20px;
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.shorts-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.short {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 180px;
  height: 320px;
  text-align: center;
  transition: transform 0.3s ease;
}

.short:hover {
  transform: scale(1.05);
}

.short-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.short-title {
  padding: 10px;
  font-size: 14px;
  color: #555;
}
