.shorts-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for better contrast */
    min-height: 100vh; /* Ensure it takes full height */
  }
  
  .shorts-video-container {
    width: 100%;
    max-width: 720px; /* Limit width on larger screens */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Clip overflow to keep rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
  
  .shorts-player iframe {
    width: 100%;
    height: 400px; /* Set a consistent height */
    border: none; /* Remove default border */
  }
  
  .shorts-player h2 {
    margin-top: 20px;
    font-size: 1.8em; /* Increased font size */
    color: #333; /* Darker text for better readability */
    text-align: center; /* Centered title */
    font-weight: bold; /* Make the title bold */
  }
  
  .shorts-player p {
    margin-top: 10px;
    color: #555; /* Slightly lighter color for the description */
    text-align: center; /* Centered description */
    padding: 0 20px; /* Padding on the sides */
    line-height: 1.5; /* Improve readability */
  }
  
  /* Add a loading state style */
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Same height as the video player */
    font-size: 1.5em; /* Bigger text */
    color: #999; /* Gray color for loading text */
  }
  
  /* Add responsive styles */
  @media (max-width: 768px) {
    .shorts-video-container {
      max-width: 100%; /* Full width on small screens */
    }
    
    .shorts-player h2 {
      font-size: 1.5em; /* Smaller title size */
    }
  }
  