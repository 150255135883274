body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.home-page {
  padding: 20px;
}

.channel-art-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.channel-art {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .channel-art-container {
      height: 175px;
  }
}

@media (max-width: 480px) {
  .channel-art-container {
      height: 150px;
  }
}

.featured-videos {
  margin-top: 20px;
}

.featured-videos h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 768px) {
  .video-list {
      flex-direction: row;
      max-height: 100%;
  }
}

@media (max-width: 480px) {
  .video-list {
      flex-direction: row;
      max-height: 100%;
  }
}

.video-item {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

@media (max-width: 768px) {
  .video-item {
      width: 100%;
      max-width: 400px;
  }
}

@media (max-width: 480px) {
  .video-item {
      width: 100%;
      max-width: 300px;
  }
}

.video-item:hover {
  transform: translateY(-5px);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.video-info {
  margin-top: 10px;
}

.video-info h4 {
  font-size: 1rem;
  margin: 0 0 5px;
  color: #333;
}

.video-info p {
  font-size: 0.85rem;
  color: #777;
}

.spinner {
  text-align: center;
  font-size: 1.2rem;
  color: #333;
}

@media (max-width: 768px) {
  .video-item {
      width: 100%;
      max-width: 400px;
  }
}

@media (max-width: 480px) {
  .video-item {
      width: 100%;
      max-width: 300px;
  }
}

nav {
  background-color: #ff0000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

ul.nav-tabs {
  overflow-x: auto;
  white-space: nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid #ccc;
}

ul.nav-tabs li {
  display: inline-block;
  margin: 0;
}

ul.nav-tabs li a {
  display: inline-block;
  padding: 15px 20px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
}

ul.nav-tabs li a.active {
  color: #fff;
  border-bottom: 3px solid #fff;
}

ul.nav-tabs li a:hover {
  border: 1px solid #f5f;
}

ul.nav-tabs::-webkit-scrollbar {
  display: none;
}

ul.nav-tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 768px) {
  ul.nav-tabs {
      padding-left: 5px;
  }

  ul.nav-tabs li a {
      padding: 10px 25px;
      font-size: 14px;
  }
}
