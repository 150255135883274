.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.about-page h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.stats-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-page p {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}

.about-details {
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.about-details h3 {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .about-details h3 {
    text-align: center;
  }
}

.about-details ul {
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
}

.about-details li {
  margin-bottom: 10px;
}

.subscribe-button {
  margin-top: 20px;
}

.subscribe-button a button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.subscribe-button a button:hover {
  background-color: #cc0000;
}

.contact-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail {
  font-size: 1.2rem;
  color: #1e90ff;
  text-decoration: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  padding: 2px;
}

.mail i {
  margin-right: 8px;
  font-size: 1.5rem;
}

.mail:hover {
  color: #fff;
  background-color: #0c69c7;
}

@media (max-width: 600px) {
  .about-page {
    padding: 10px;
  }

  .about-page h2 {
    font-size: 1.5rem;
  }

  .about-page p {
    font-size: 1rem;
  }

  .about-details h3 {
    font-size: 1.2rem;
  }

  .about-details ul {
    font-size: 1rem;
  }

  .subscribe-button a button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .mail {
    font-size: 1rem;
  }

  .mail i {
    font-size: 1.2rem;
  }
}
